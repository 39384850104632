<template>
    <li class="feature" @mouseover="onMouseOver" @click.stop="onClick">
        <div v-if="hasTemplate" class="feature-details-wrapper category-data-content">
            <div class="panel panel-control mobile-panel-control" @click="onClose">
                <div class="panel-control-icon" />
                <h6>Back</h6>
            </div>
            <component
                :is="templateComponent"
                :record="row"
                :category="category"
                viewing-from="map"
                @mouseover="onMouseOver"
                @click="onClick"
            />
        </div>
        <div v-else>
            <h6 class="feature-header">{{ name }}</h6>
            <p class="feature-address">
                {{ address }}
            </p>
        </div>
    </li>
</template>

<script>
import { debounce, isEmpty } from 'utils/lodash';
import { layerDetails } from 'mixins';

export default {
    mixins: [layerDetails],
    props: {
        row: {
            type: Object,
            required: true,
        },
        category: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        name() {
            return this.rowProperty('properties.name');
        },
        details() {
            return this.rowProperty('properties.display_details', {});
        },
        address() {
            return this.rowProperty('properties.address');
        },
        hasTemplate() {
            const hasTemplate = !isEmpty(this.row) && this.templateName !== null;
            return hasTemplate;
        },
        templateName() {
            return this.details.template;
        },
        templateComponent() {
            return require(`@/components/records/templates/${this.templateName}/details.vue`).default;
        },
    },
    methods: {
        onMouseOver: debounce(function () {
            this.$emit('hover', this.row);
        }, 250),
        onClick() {
            this.$emit('click', this.row);
        },
        onClose() {
            this.$events.$emit('map:marker:deselect');
        },
    },
};
</script>
