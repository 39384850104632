<template>
    <li class="mini-detail-view feature" v-if="hasMiniDetailView">
        <a v-if="allowLinks && miniDetailUrl" :href="miniDetailUrl" target="_blank">
            <div v-html="miniDetailView" />
        </a>
        <div v-else v-html="miniDetailView" @mouseover="onMouseOver" @click="onClick" />
    </li>
    <li v-else>
        <h1 class="feature-header">{{ name }}</h1>
        {{ address }}
    </li>
</template>
<script>
import { debounce, get } from '@/utils/lodash';
import marked from 'marked';

export default {
    props: {
        row: {
            type: Object,
            required: true,
        },
        category: {
            type: Object,
            default: () => {},
        },
        allowLinks: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        name() {
            return get(this.row, 'properties.name');
        },
        address() {
            return get(this.row, 'properties.address');
        },
        hasMiniDetailView() {
            return this.row.properties.display_details.description;
        },
        miniDetailView() {
            const type = this.row.properties.display_details.description_format;
            const description = this.row.properties.display_details.description;

            return type === 'markdown' ? marked(description) : description;
        },
        miniDetailUrl() {
            return this.row.properties.display_details.url;
        },
    },
    methods: {
        onMouseOver: debounce(function () {
            this.$emit('hover', this.row);
        }, 250),
        onClick() {
            this.$emit('click', this.row);
        },
    },
};
</script>
