<template>
    <div class="panel feature feature-details-panel">
        <div class="feature-details-wrapper">
            <div v-if="record === null">
                <r-loading-spinner></r-loading-spinner>
            </div>
            <div v-else-if="hasTemplate">
                <block :row="row" :category="category" />
            </div>
            <div v-else-if="hasMiniDetailView">
                <mini-detail :row="record" :category="category" allowLinks />
            </div>
            <div v-else>
                <h1 class="feature-header">{{ name }}</h1>
                {{ address }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { isEmpty } from 'utils/lodash';
import MiniDetail from './components/mini-detail';
import Block from './components/block';
import { layerDetails } from 'mixins';

export default {
    mixins: [layerDetails],
    props: {
        row: {
            type: Object,
            required: true,
        },
        expanded: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        Block,
        MiniDetail,
    },
    data: () => ({
        record: null,
    }),
    computed: {
        ...mapGetters('categories', ['findCategoryBySlug']),
        category() {
            return this.findCategoryBySlug(this.row.properties.category_slug);
        },
        name() {
            return this.rowProperty('properties.name');
        },
        address() {
            return this.rowProperty('properties.address');
        },
        hasMiniDetailView() {
            return this.record.properties.display_details.description;
        },
        hasTemplate() {
            return !isEmpty(this.record) && this.templateName !== null;
        },
        templateName() {
            return this.record?.properties?.display_details?.template;
        },
    },
    async created() {
        this.record = await this.getRecordGeoJSON({
            category: this.row.properties.category_slug,
            id: this.row.properties.unique_id,
        });
    },
    methods: {
        ...mapActions('records', { getRecordGeoJSON: 'getGeoJSON' }),
    },
};
</script>

<style>
.feature-details-panel {
    padding: 0;
}

.layer-details-desktop-view {
    padding-bottom: 0;
}

.mini-detail-view {
    padding: 0.25rem 0.75rem;
}
</style>
